var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.config.nav_active === 1 && _vm.headerTemplate === 'base')?_c('app-header',{attrs:{"config":_vm.config}}):_vm._e(),(_vm.config.nav_active === 1 && _vm.headerTemplate === 'soauto_vgrp')?_c('app-header-soauto-vgrp',{attrs:{"config":_vm.config}}):_vm._e(),(_vm.config.nav_active === 1 && _vm.headerTemplate === 'renault_santogal')?_c('app-header-santogal-renault',{attrs:{"config":_vm.config}}):_vm._e(),(_vm.config.nav_active === 1 && _vm.headerTemplate === 'dacia_santogal')?_c('app-header-santogal-dacia',{attrs:{"config":_vm.config}}):_vm._e(),_c('div',{staticClass:"container-fluid p-0"},[(_vm.config.catalog !== null)?_c('catalog-container-v2-refresh',{attrs:{"config":_vm.config,"form-data":_vm.formData,"setup":_vm.setup,"privacy-text":_vm.setPrivacyText(),"campaign-contract":_vm.campaignContract}}):_vm._e()],1),(_vm.jsonConfig.cta !== undefined)?_c('div',{staticClass:"container-fluid cta-bg pt-lg-3",attrs:{"id":"cta2"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-3 mt-md-3 cta mb-3 mb-md-3 text-center d-none d-lg-block"},[_c('p',[_c('span',{staticClass:"cta_text_1",domProps:{"innerHTML":_vm._s(_vm.jsonConfig.cta.text1)}}),_c('span',{staticClass:"cta_text_2",domProps:{"innerHTML":_vm._s(_vm.jsonConfig.cta.text2)}})])])])]):_vm._e(),(_vm.jsonConfig.countdown !== undefined)?_c('div',{staticClass:"container-fluid pt-2 pb-2",attrs:{"id":"countdown-clock"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('flip-countdown',{attrs:{"deadline":_vm.jsonConfig.countdown.deadline,"labels":_vm.labels}})],1)])])]):_vm._e(),(_vm.jsonConfig.container_after_form !== undefined)?_c('div',{staticClass:"container-fluid container_after_form",class:{
      'd-none d-md-block': _vm.jsonConfig.container_after_form.active_top,
    }},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},_vm._l((_vm.jsonConfig.container_after_form.bullets),function(item,i){return _c('div',{key:i,staticClass:"col-12 col-md-4 pb-3 pb-md-0",class:{
                'offset-md-4':
                  _vm.jsonConfig.container_after_form.bullets.length === 1,
                'offset-md-2':
                  _vm.jsonConfig.container_after_form.bullets.length === 2 &&
                  i !== 1,
              },attrs:{"index":i}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('img',{attrs:{"src":_vm.config.bullets[i],"alt":"","width":"60px"}})]),_c('div',{staticClass:"col-9"},[_c('h4',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])])])}),0)])])])]):_vm._e(),(_vm.jsonConfig.navigate_out_zone !== undefined)?_c('div',{staticClass:"container-fluid container-navigate-out",attrs:{"id":"navigate-out-top"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-md-flex justify-content-md-center text-center align-content-center"},[_c('div',{staticClass:"pb-1 pt-1",domProps:{"innerHTML":_vm._s(_vm.jsonConfig.navigate_out_zone.text)}}),_c('div',{staticClass:"pb-1 pt-2 pt-md-1 p-md-0 pl-0 pl-md-4"},[_c('a',{attrs:{"href":_vm.jsonConfig.navigate_out_zone.href,"target":"_blank","rel":"noopener"},domProps:{"innerHTML":_vm._s(_vm.jsonConfig.navigate_out_zone.button_text)}})])])])])]):_vm._e(),(_vm.jsonConfig.tabbed_static_content !== undefined)?_c('div',{staticClass:"container-fluid container-tabbed-static-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('tabbed-static-content',{attrs:{"config":_vm.jsonConfig.tabbed_static_content}})],1)])])]):_vm._e(),(_vm.jsonConfig.slideshow !== undefined)?_c('div',{staticClass:"container-fluid container-slideshow"},[_c('div',{staticClass:"row"},[_c('slideshow',{attrs:{"json-config":_vm.jsonConfig,"images":_vm.config.slideshow}})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-primary slideshow-btn",attrs:{"href":"#form"},domProps:{"innerHTML":_vm._s(_vm.jsonConfig.slideshow.button)}})])]):_vm._e(),(
      _vm.jsonConfig.slideshowv2 !== undefined &&
      _vm.jsonConfig.slideshowv2.length > 0
    )?_c('div',{staticClass:"container-fluid container-slideshow"},[_c('slideshow-v2',{attrs:{"json-config":_vm.jsonConfig}})],1):_vm._e(),_c('div',{staticClass:"container-fluid container-gallery"},[(_vm.jsonConfig.gallery !== undefined)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-5",staticStyle:{"margin-left":"0","margin-right":"0"}},[_c('div',{staticClass:"col-12"},[_c('gallery',{attrs:{"gallery-images":_vm.config.gallery,"size":_vm.jsonConfig.gallery.size}}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-primary slideshow-btn",attrs:{"href":"#form"},domProps:{"innerHTML":_vm._s(_vm.jsonConfig.gallery.button)}})])],1)])]):_vm._e(),(_vm.jsonConfig.galleryv2 !== undefined)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-5",staticStyle:{"margin-left":"0","margin-right":"0"}},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"text-center pb-3",domProps:{"innerHTML":_vm._s(
              _vm.jsonConfig.galleryv2.header !== undefined
                ? _vm.jsonConfig.galleryv2.header
                : 'Viaturas em destaque!'
            )}}),_c('gallery2',{attrs:{"gallery-images":_vm.jsonConfig.galleryv2.images,"size":_vm.jsonConfig.galleryv2.size}}),_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.jsonConfig.galleryv2.button_active)?_c('a',{staticClass:"btn btn-primary slideshow-btn",attrs:{"href":"#form"},domProps:{"innerHTML":_vm._s(_vm.jsonConfig.galleryv2.button)}}):_vm._e()])],1)])]):_vm._e(),(
        _vm.jsonConfig.galleryv3 !== undefined && _vm.jsonConfig.galleryv3.length > 0
      )?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-5",staticStyle:{"margin-left":"0","margin-right":"0"}},_vm._l((_vm.jsonConfig.galleryv3),function(gallery){return _c('div',{staticClass:"col-12 mb-3"},[_c('h2',{staticClass:"text-center pb-3",domProps:{"innerHTML":_vm._s(
              gallery.header !== undefined
                ? gallery.header
                : 'Viaturas em destaque!'
            )}}),_c('gallery2',{attrs:{"gallery-images":gallery.images,"size":gallery.size}}),_c('div',{staticClass:"d-flex justify-content-center"},[(gallery.button_active)?_c('a',{staticClass:"btn btn-primary slideshow-btn",attrs:{"href":"#form"},domProps:{"innerHTML":_vm._s(gallery.button)}}):_vm._e()])],1)}),0)]):_vm._e(),(_vm.jsonConfig.youtube !== undefined)?_c('div',{staticClass:"container"},[_c('app-video',{attrs:{"json-config":_vm.jsonConfig}})],1):_vm._e()]),(_vm.formData !== null)?_c('div',{staticClass:"container-fluid container-top bottom-form"},[_c('div',{staticClass:"container position"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],staticClass:"col-12 right-side",class:{
            'offset-lg-4 col-lg-4': !_vm.formData.fat_form,
            'offset-lg-3 offset-md-2 col-md-8 col-lg-6': _vm.formData.fat_form,
          },attrs:{"id":"form"}},[_c('contact-form',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.changeVisible),expression:"changeVisible"}],attrs:{"config":_vm.config,"form-data":_vm.formData,"setup":_vm.setup,"json-config":_vm.jsonConfig}})],1)])])]):_vm._e(),(_vm.config.catalog.back_link_active === 1)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-3 pb-2 text-center"},[_c('a',{staticClass:"back-link",staticStyle:{"text-decoration":"underline"},attrs:{"href":_vm.setUrlQuery(),"rel":"noopener"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.config.catalog.back_link_text)}})])])])]):_vm._e(),(
      _vm.config.catalog !== null &&
      _vm.jsonConfig.catalog_feed !== undefined &&
      !_vm.catalogReady
    )?_c('div',{staticClass:"text-center mt-3"},[_vm._m(0)]):_vm._e(),(
      _vm.config.catalog !== null &&
      _vm.jsonConfig.catalog_feed !== undefined &&
      _vm.catalogReady
    )?_c('div',{staticClass:"container-fluid container-catalog-feed"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('catalog-feed',{attrs:{"catalog-data":_vm.catalogData,"module-config":_vm.jsonConfig.catalog_feed,"config":_vm.config,"fuel-type":_vm.fuelType,"makes":_vm.makes,"models":_vm.models,"addresses":_vm.requestAddresses,"prices":_vm.prices}})],1)])])]):_vm._e(),(_vm.jsonConfig.navigate_out_zone !== undefined)?_c('div',{staticClass:"container-fluid container-navigate-out",staticStyle:{"display":"none"},attrs:{"id":"navigate-out-bottom"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-md-flex justify-content-md-center text-center align-content-center"},[_c('div',{staticClass:"pb-1 pt-1",domProps:{"innerHTML":_vm._s(_vm.jsonConfig.navigate_out_zone.text)}}),_c('div',{staticClass:"pb-1 pt-2 pt-md-1 p-md-0 pl-0 pl-md-4"},[_c('a',{attrs:{"href":_vm.jsonConfig.navigate_out_zone.href,"target":"_blank","rel":"noopener"},domProps:{"innerHTML":_vm._s(_vm.jsonConfig.navigate_out_zone.button_text)}})])])])])]):_vm._e(),_c('div',{staticClass:"container-fluid container-bottom mt-3",staticStyle:{"margin-bottom":"5rem"}},[(
        _vm.addressStyle === 'v2' && _vm.footerLocations.length > 0 && _vm.loadLocations
      )?_c('address-v2',{attrs:{"config":_vm.footerLocations}}):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.setup.disclaimer_zone === 'bottom')?_c('div',{staticClass:"col-12 mt-3 mt-md-3 disclaimer mb-3 mb-md-3"},[_c('p',{attrs:{"id":"privacy2"},domProps:{"innerHTML":_vm._s(_vm.setPrivacyText())}}),_c('p',{attrs:{"id":"contract2"}},[_c('strong',{domProps:{"innerHTML":_vm._s(_vm.campaignContract)}})]),_c('p',{attrs:{"id":"disclaimer2"},domProps:{"innerHTML":_vm._s(_vm.setup.campaign_disclaimer)}})]):_vm._e()])]),(
        _vm.addressStyle === 'v1' && _vm.footerLocations.length > 0 && _vm.loadLocations
      )?_c('address-v1',{attrs:{"config":_vm.footerLocations,"setup":_vm.setup}}):_vm._e()],1),(_vm.config.sticky_button === 1)?_c('div',{staticClass:"sticky-button d-none d-md-none"},[_c('div',{staticClass:"d-flex justify-content-start",class:{
        'justify-content-around':
          (_vm.config.sticky_button_text !== null &&
            _vm.config.sticky_button_call_number !== null) ||
          (_vm.config.sticky_button_text === null &&
            _vm.config.sticky_button_call_number !== null &&
            !_vm.whatsappActive) ||
          (_vm.config.sticky_button_call_number === null &&
            _vm.config.sticky_button_text !== null &&
            !_vm.whatsappActive),
      }},[(_vm.config.sticky_button_text !== null)?_c('a',{staticClass:"btn btn-primary mr-1 ml-1",attrs:{"href":"#form"},domProps:{"innerHTML":_vm._s(_vm.config.sticky_button_text)}}):_vm._e(),(_vm.config.sticky_button_call_number !== null)?_c('a',{staticClass:"btn btn-primary mr-1 ml-1",attrs:{"href":'tel:' + _vm.config.sticky_button_call_number},on:{"click":function($event){return _vm.triggerCall()}}},[_vm._v("Ligar")]):_vm._e()])]):_vm._e(),(_vm.footerTemplate === 'base')?_c('app-footer',{attrs:{"config":_vm.config,"setup":_vm.setup,"seller-id":_vm.sellerId,"force-form-marketing-texts":_vm.formForceMarketingTexts}}):_vm._e(),(_vm.footerTemplate === 'soauto_vgrp')?_c('app-footer-soauto-vgrp',{attrs:{"config":_vm.config,"setup":_vm.setup,"seller-id":_vm.sellerId}}):_vm._e(),(_vm.footerTemplate === 'lubrigaz')?_c('app-footer-lubrigaz',{attrs:{"config":_vm.config,"setup":_vm.setup,"seller-id":_vm.sellerId}}):_vm._e(),(_vm.footerTemplate === 'lubrigaz_skoda')?_c('app-footer-lubrigaz-skoda',{attrs:{"config":_vm.config,"setup":_vm.setup,"seller-id":_vm.sellerId}}):_vm._e(),(_vm.footerTemplate === 'lubrisport')?_c('app-footer-lubrisport',{attrs:{"config":_vm.config,"setup":_vm.setup,"seller-id":_vm.sellerId}}):_vm._e(),(_vm.footerTemplate === 'renault_santogal')?_c('app-footer-santogal-renault',{attrs:{"config":_vm.config,"setup":_vm.setup,"seller-id":_vm.sellerId}}):_vm._e(),(_vm.footerTemplate === 'dacia_santogal')?_c('app-footer-santogal-dacia',{attrs:{"config":_vm.config,"setup":_vm.setup,"seller-id":_vm.sellerId}}):_vm._e(),_c('debugger',{attrs:{"config":_vm.config}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-ring"},[_c('div'),_c('div'),_c('div'),_c('div')])
}]

export { render, staticRenderFns }